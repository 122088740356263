<template>
  <v-dialog
    v-model="isShowModal"
    transition="dialog-top-transition"
    max-width="600">
    <template v-slot:default>
      <v-card>
        <v-toolbar
          color="primary"
          dark>
          <img
            v-if="paymentMethod==PaymentMethodType.MOMO_WALLET"
            src="@/assets/logo_momo.png"
            class="paymentLogo">
          <img
            v-else-if="paymentMethod==PaymentMethodType.ZALOPAY_WALLET"
            src="@/assets/logo_zalopay.png"
            class="paymentLogo">
          {{ $t(getPaymentMethodName(paymentMethod)) }}
        </v-toolbar>
        <v-card-text>
          <div class="my-3">
            <div class="text-left font-large pa-2">{{ $t('cart.voucherRecipientInfo') }}</div>
            <div class="ml-5 d-flex">
              <span>{{ $t('common.fullname') + ': ' }}
                <strong>
                  {{ deliveryInfo.fullname ? deliveryInfo.fullname :
                  (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.fullname ? SHIPMENT_DETAILS_DATA.fullname : '') }}
                </strong>
              </span>
            </div>
            <div class="ml-5 d-flex">
              <span>{{ $t('common.phone') + ': ' }}
                <strong>
                  {{ deliveryInfo.phone ? deliveryInfo.phone :
                  (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.phone ? SHIPMENT_DETAILS_DATA.phone : '') }}
                </strong>
              </span>
            </div>
            <div class="ml-5 d-flex">
              <span>{{ $t('common.email') + ': ' }}
                <strong>{{ deliveryInfo.email ? deliveryInfo.email :
                (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.email ? SHIPMENT_DETAILS_DATA.email : '') }}
                </strong>
              </span>
            </div>
            <div class="font-small text-left ml-5 pt-1 pl-3">
              <span class="red--text">{{ $t('cart.voucherInfoSentViaEmail') }}. {{ $t('cart.makeSureEmailIsEnteredCorrectly') }}</span>
            </div>
          </div>
          <v-divider />
          <div class="text-left font-large mt-1 pa-2">
            <span>{{ $t('cart.paymentAmount') + ': ' }}
              <span
                id="paymentAmount"
                style="font-weight: bold; color: #ff6d6d;">{{ paymentAmount }} VND</span>
            </span>
          </div>
          <div
            v-if="description"
            class="text-left font-large pa-2 my-1"
            style="white-space: pre-wrap;">{{ description }}</div>
          <div v-if="paymentMethod==PaymentMethodType.BANK_TRANSFER">
            <div
              v-if="isConfirmedPayment"
              class="text-left px-3">
              <span>{{ $t('paymentMethod.transferContent') }}: <input
                id="transferContent"
                :value="contentTransfer"
                :style="`width: ${ contentTransfer.length + 1 }ch;`"
                type="text"
                disabled>
                <v-icon
                  class="cursor: pointer;"
                  @onmouseout="outFunc"
                  @click="copyTextToClipboard('transferContent')">mdi-content-copy</v-icon>
              </span>
              <div
                v-for="(bankInfo, index) in bankInfoList"
                :key="bankInfo + index">
                <span class="d-block font-weight-bold mt-2">{{ index + 1 }}. {{ bankInfo.name_bank }}</span>
                <div class="ml-4 d-flex">
                  <span>{{ $t('paymentMethod.bankInfo.accountNumber') }}: <input
                    :value="bankInfo.number_account"
                    :id="bankInfo.number_account"
                    :style="`width: ${ bankInfo.number_account ? bankInfo.number_account.length + 1 : 1 }ch;`"
                    type="text"
                    disabled>
                    <v-icon
                      class="cursor: pointer;"
                      @onmouseout="outFunc"
                      @click="copyTextToClipboard(bankInfo.number_account)">mdi-content-copy</v-icon>
                  </span>
                </div>
                <div class="ml-4 d-flex">
                  <span>{{ $t('paymentMethod.bankInfo.bankName') }}: <strong>{{ bankInfo.name_account }}</strong></span>
                </div>
                <div class="ml-4 d-flex">
                  <span>{{ $t('paymentMethod.bankInfo.bankBranch') }}: <strong>{{ bankInfo.branch_bank }}</strong></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="paymentMethod==PaymentMethodType.BAO_KIM_WALLET">
            <div class="text-left font-large pa-2">{{ $t('baoKimConfig.baoKimPaymentMethodType') }}</div>
            <div
              v-for="(paymentMethod, index) in baoKimPaymentMethods"
              :key="paymentMethod + index"
              class="d-flex"
            >
              <div
                class="ml-4 form-check"
              >
                <input
                  :id="`${paymentMethod.text}${paymentMethod.value}`"
                  :value="paymentMethod.value"
                  v-model="baoKimPaymentMethodTypeSelected"
                  class="form-check-input"
                  type="radio"
                  name="baoKimPaymentMethods"
                  @change="getBaoKimPaymentInPaymentMethod(baoKimPaymentMethodTypeSelected)"
                >
                <label
                  :for="`${paymentMethod.text}${paymentMethod.value}`"
                  class="form-check-label ml-2">
                  {{ $t(paymentMethod.text) }}
                </label>
              </div>
            </div>
          </div>
          <v-divider v-if="baoKimPaymentMethodTypeSelected !== null" />
          <div v-if="paymentMethod==PaymentMethodType.BAO_KIM_WALLET && baoKimPaymentMethodTypeSelected !== null">
            <div class="text-left font-large pa-2">{{ $t('baoKimConfig.baoKimPaymentMethod') }}</div>
            <div style="overflow: auto; max-height: 250px;">
              <div
                v-for="(paymentMethod, index) in baoKimPaymentInPaymentMethodList"
                :key="paymentMethod + index"
                class="d-flex"
              >
                <!-- {{ paymentMethod }} -->
                <div
                  class="ml-4 form-check"
                >
                  <input
                    :id="`${paymentMethod.id}${paymentMethod.bank_short_name}`"
                    :value="paymentMethod.id"
                    v-model="baoKimPaymentMethodSelect"
                    class="form-check-input"
                    type="radio"
                    name="baoKimPaymentMethodSelect"
                  >
                  <img
                    :src="paymentMethod.bank_logo ? paymentMethod.bank_logo : noImg"
                    style="border: 2px solid #fff; max-width: 50px; max-height: 50px; width: 50px">
                  <label
                    :for="`${paymentMethod.id}${paymentMethod.bank_short_name}`"
                    class="form-check-label ml-2">
                    {{ $t(paymentMethod.name) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!isConfirmedPayment"
            color="error"
            @click="isShowModal = false">{{ $t('payment.cancelPayment') }}</v-btn>
          <v-btn
            v-if="!isConfirmedPayment"
            color="primary"
            @click="onConfirm">{{ $t('payment.confirmPayment') }}</v-btn>
          <v-btn
            v-if="isConfirmedPayment"
            color="warning"
            @click="isShowModal = false">{{ $t('payment.cancelPayment') }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import PaymentMethodType from 'enum/paymentMethodType'
import StoreChannelType from 'enum/storeChannelType'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  props: {
  },
  data: () => ({
    isShowModal: false,
    bankInfoList: [],
    description: null,
    orderCode: null,
    contentTransfer: '',
    PaymentMethodType: PaymentMethodType,
    paymentMethod: PaymentMethodType.CASH_ON_DELIVERY,
    deliveryInfo: {
      phone: null,
      email: null,
      fullname: null
    },
    baoKimPaymentMethods: [],
    baoKimPaymentMethodTypeSelected: null,
    baoKimPaymentInPaymentMethodList: [],
    baoKimPaymentMethodSelect: null,
    noImg: require('../../../../assets/noimage.png'),
    paymentAmount: null,
    isConfirmedPayment: false,
    agentDefaultId: null
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'SHIPMENT_DETAILS_DATA',
      'GET_BAO_KIM_PAYMENT_LIST_DATA'
    ])
  },
  watch: {
    GET_BAO_KIM_PAYMENT_LIST_DATA: function () {
      let paymentMethodList = this.GET_BAO_KIM_PAYMENT_LIST_DATA && this.GET_BAO_KIM_PAYMENT_LIST_DATA.data
      this.getBaoKimPaymentMethodList(paymentMethodList)
    }
  },
  async created () {
    await this.getSettingConfig()
    let filter = {
      params: {
        storeChannelId: this.getStoreChannelId()
      }
    }
    await this.GET_BANK_TRANSFER_LIST(filter).then(
      function (res) {
        let data = res.data
        this.bankInfoList = []
        for (let i = 0, size = data.length; i < size; i++) {
          this.bankInfoList.push(data[i])
        }
      }.bind(this)
    )
    this.getDeliveryInfo()
    let paymentMethodList = this.GET_BAO_KIM_PAYMENT_LIST_DATA && this.GET_BAO_KIM_PAYMENT_LIST_DATA.data
    this.getBaoKimPaymentMethodList(paymentMethodList)
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    generateTransferContent: function () {
      let fullname = this.GET_CURRENT_ACCOUNT ? functionUtils.concatFullname(this.GET_CURRENT_ACCOUNT.lastname, this.GET_CURRENT_ACCOUNT.firstname) : ''
      return this.orderCode + '_' + functionUtils.removeVietnameseTones(fullname)
    },
    setTootltipElementsHint: function (text) {
      var tooltips = document.getElementsByName('copyTooltip')
      var i
      for (i = 0; i < tooltips.length; i++) {
        tooltips[i].innerHTML = text
      }
    },
    outFunc () {
      // var tooltip = document.getElementById("myTooltip");
      // tooltip.innerHTML = this.$t("common.copyToClipboard");
      this.setTootltipElementsHint(this.$t('common.copyToClipboard'))
    },
    /**
    * Copy text to clipboard
    */
    copyTextToClipboard: function (elementId) {
      /* Get the text field */
      var copyText = document.getElementById(elementId)
      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value)
      this.setTootltipElementsHint(this.$t('common.copied') + ': ' + copyText.value)
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    onConfirm: function () {
      if (this.paymentMethod === PaymentMethodType.BAO_KIM_WALLET) {
        if (functionUtils.isEmptyString(this.baoKimPaymentMethodTypeSelected)) {
          this.ON_SHOW_TOAST({
            message: this.$t('baoKimConfig.baoKimPaymentMethodType'),
            styleType: ToastType.ERROR
          })
        } else if (functionUtils.isEmptyString(this.baoKimPaymentMethodSelect)) {
          this.ON_SHOW_TOAST({
            message: this.$t('baoKimConfig.baoKimPaymentMethod'),
            styleType: ToastType.ERROR
          })
        } else {
          this.isShowModal = false
          this.$emit('confirm', {
            baoKimPaymentMethodTypeSelected: this.baoKimPaymentMethodTypeSelected,
            baoKimPaymentMethodSelect: this.baoKimPaymentMethodSelect
          })
        }
      } else {
        if (this.paymentMethod !== PaymentMethodType.BANK_TRANSFER) {
          this.isShowModal = false
        } else {
          this.isConfirmedPayment = true
        }
        this.$emit('confirm')
      }
    },
    onHideModal: function () {
      this.isShowModal = false
    },
    onShowModal: function (orderCode, paymentMethod, description, paymentAmount) {
      this.orderCode = orderCode
      this.description = description
      this.paymentMethod = paymentMethod
      if (paymentMethod === PaymentMethodType.BANK_TRANSFER) {
        this.contentTransfer = this.generateTransferContent()
      }
      this.paymentAmount = paymentAmount
      this.isShowModal = true
      this.isConfirmedPayment = false
    },
    getDeliveryInfo: function () {
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      this.GET_DELIVERY_INFO_DETAIL(filter).then(
        function (res) {
          let data = res.data
          this.deliveryInfo.phone = !functionUtils.isEmptyString(data.phone)
            ? data.phone : null
          this.deliveryInfo.email = !functionUtils.isEmptyString(data.email)
            ? data.email : null
          this.deliveryInfo.fullname = !functionUtils.isEmptyString(data.fullname)
            ? data.fullname : null
        }.bind(this)
      )
    },
    getBaoKimPaymentMethodList: function (dataList) {
      this.baoKimPaymentMethods = []
      if (dataList !== undefined) {
        let baoKimPaymentMethodList = stringUtils.BaoKimPaymentMethodList
        baoKimPaymentMethodList.forEach(e => {
          let index = dataList.findIndex(x => x.type === e.value)
          if (index !== -1) {
            this.baoKimPaymentMethods.push(e)
          }
        })
      }
    },
    getBaoKimPaymentInPaymentMethod: function (paymentMethodSelect) {
      this.baoKimPaymentInPaymentMethodList = []
      let paymentMethodList = this.GET_BAO_KIM_PAYMENT_LIST_DATA && this.GET_BAO_KIM_PAYMENT_LIST_DATA.data
      if (paymentMethodList !== undefined) {
        let resultList = paymentMethodList.filter(x => x.type === paymentMethodSelect)
        this.baoKimPaymentInPaymentMethodList = resultList
      }
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    getSettingConfig: async function () {
      let storeChannelsKey = stringUtils.storeChannelsKey + `${this.storeChannelsIdInRouteParams()}`
      let filter = {
        params: {
          getWithKey: true,
          key: storeChannelsKey
        }
      }
      await this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.agentDefaultId = parseInt(data[0].value)
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_BANK_TRANSFER_LIST',
      'GET_DELIVERY_INFO_DETAIL',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
input {
  color: #2c3e50;
  font-weight: bold;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.paymentLogo {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  vertical-align: middle;
}
</style>
