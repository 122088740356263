<template>
  <v-container
    :style="
      `width: ${
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? ''
          : '1200px !important'
      };`
    "
  >
    <v-layout
      wrap
      layout>
      <v-flex
        xs12
        md12>
        <p
          style="color: #ff6d6d;"
          class="font-weight-bold">
          {{ $t("thirdParty.infoReceiveVouchers") }}
        </p>
        <div>
          <v-icon
            class="mr-2"
            color="#01baef">mdi-account</v-icon>
          <span>
            {{ email ? fullname : (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.fullname ? SHIPMENT_DETAILS_DATA.fullname : '') }}
          </span>
          <span class="mx-3">|</span>
          <span>{{ email ? phone : (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.phone ? SHIPMENT_DETAILS_DATA.phone : '') }}</span>
        </div>
        <div class="d-flex justify-content-around">
          <div>
            <span
            ><v-icon
              class="mr-2"
              color="#01baef">mdi-email</v-icon
            >{{ email ? email : (SHIPMENT_DETAILS_DATA && SHIPMENT_DETAILS_DATA.email ? SHIPMENT_DETAILS_DATA.email : '') }}</span
            >
          </div>
          <div
            class="text-right"
            @click="goToDeliveryInfo()">
            <v-icon
              medium
              @click="goToDeliveryInfo()"
            >mdi-chevron-right</v-icon
            >
          </div>
        </div>
      </v-flex>
      <v-flex
        xs12
        md12
        class="mt-2 py-2"
        style="border-bottom: 1px solid #e5e5e5; border-top: 1px solid #e5e5e5;"
      >
        <div class="d-flex justify-content-around align-center">
          <div>
            <span
            >{{ $t("productOrder.title") }}
            <span class="font-weight-bold">#{{ orderCode }}</span></span
            >
          </div>
        </div>
      </v-flex>
      <v-flex
        class="mt-4"
        xs12
        md7>
        <v-layout
          wrap
          layout>
          <v-flex xs12>
            <v-layout
              v-for="(cart, index) in cartList"
              :key="cart + index"
              class="pt-1 pb-1"
              wrap
              layout
            >
              <v-flex
                xs4
                md4>
                <figure class="sixteen-nine-img">
                  <img :src="cart.image" >
                </figure>
              </v-flex>
              <v-flex
                class="pl-2"
                xs7
                md7>
                <p class="font-weight-bold">{{ cart.name }}</p>
                <div class="font-weight-bold mt-1">
                  <div
                    v-if="cart.pricePromo"
                    class="mr-2"
                    style="color: #ff6d6d;"
                  >
                    {{ formatMoney(cart.pricePromo) }} VND
                  </div>
                  <div
                    :style="
                      cart.pricePromo
                        ? 'text-decoration: line-through; font-size: 12px; font-weight: normal; color: #4B4B4B;'
                        : 'color: #ff6d6d;'
                    "
                  >
                    {{ formatMoney(cart.price) }} VND
                  </div>
                  <div v-if="cart.numberOfUsesVoucherValid !== null">
                    <strong
                      :style="`color: ${cart.numberOfUsesVoucherValid === 0 ? 'red' : ''}`"> {{ $t('cart.productCanBuyTimes', {'0': cart.numberOfUsesVoucherValid}) }}
                    </strong>
                  </div>
                </div>
              </v-flex>
              <v-flex
                xs1
                md1>
                <div>
                  <p>
                    x<span class="font-weight-bold">{{ cart.quantity }}</span>
                  </p>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        class="mt-4"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="mt-4 d-inline-block">
          <div class="d-inline-block">
            <v-icon>mdi-credit-card</v-icon>
          </div>
          <span class="ml-2 d-inline-block">{{ $t("evoucher.pay") }}</span>
        </div>
      </v-flex>
      <v-flex
        class="mt-4"
        style="border-top: 1px solid #e5e5e5;">
        <div class="d-flex mt-4">
          <p>{{ $t("cart.provisional") }}</p>
          <p class="text-right font-weight-bold">{{ getTotalPrice() }}</p>
        </div>
        <div class="d-flex">
          <p>{{ $t("thirdParty.transportFee") }}</p>
          <p class="text-right font-weight-bold">{{ 0 }}</p>
        </div>
        <div style="width: 100%; height: 1px; background-color: #e5e5e5;" />
        <div class="d-flex mt-4">
          <p>{{ $t("thirdParty.total") }}</p>
          <p class="text-right font-weight-bold">{{ getTotalPrice() }}</p>
        </div>
      </v-flex>
      <v-flex
        class="mt-2"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="mt-2">
          <div>
            <span>{{ $t("cart.promotion.code") + ':' }}</span>
          </div>
          <div class="d-flex space-between align-center">
            <v-form
              ref="entryForm"
              v-model="valid"
              class="d-flex align-center"
              @submit.prevent="applyPromotionCode"
            >
              <v-text-field
                v-model="promotionCode"
                :rules="promotionCodeRules"
                :disabled="cartList.length === 0"
                :label="$t('cart.promotion.code')"
                class="mr-1"
                clearable
              />
              <v-btn
                :disabled="cartList.length === 0"
                type="submit"
                color="warning"
                small>{{ $t('cart.promotion.apply') }}</v-btn>
            </v-form>
          </div>
        </div>
        <!-- Price reduced -->
        <div
          v-if="promotionCodeValid"
          class="d-block mt-2">
          <div
            v-for="(item, index) in promotionDataList"
            :key="item + index"
          >
            <div
              style="display: flex;justify-content: space-between;">
              <div>
                <span
                  v-if="item.applyType === promotionTypeEnum.PRICE"
                  class="text-left"
                >
                  {{ $t("paymentMethod.discountOnBill") }}
                </span>
                <span
                  v-else
                  class="text-left">{{ $t("paymentMethod.discountOnVoucher") }}
                  <span style="font-weight: bold;">{{ item.voucherName }}:</span>
                </span>
              </div>
              <div style="display: flex; padding-left: 5px;">
                <!-- <span>{{ getPriceReduced(priceReduced) }}</span> -->
                <span>{{ item.promotionType === promotionTypeEnum.PRICE ? formatMoney(item.promotionValue) : item.promotionValue + '%' }}</span>
                <v-icon
                  class="red--text cursor-pointer ml-2"
                  style="float: right; align-self: start;"
                  @click="onRemovePromotion(item, index)"
                >mdi-close</v-icon
                >
              </div>
            </div>
            <div
              :style="`${index < promotionDataList.length - 1 ? 'border-bottom: 1px solid #cccccc; padding-bottom: 10px' : ''}`"
              class="d-flex">
              {{ $t('paymentMethod.applicableCode') }}: {{ item.promotionCode }}
            </div>
          </div>
        </div>
        <div
          v-if="promotionCodeValid"
          class="d-block mt-2">
          <div style="display: flex;justify-content: space-between;">
            <span>{{
              $t("cart.promotion.totalPriceToPay") + ':'
            }}</span>
            <span>{{ formatMoney(getFinalPrice()) }}</span>
          </div>
        </div>
      </v-flex>
      <!--  -->
      <v-flex
        class="mt-4"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="d-flex mt-4">
          <p>{{ $t("paymentMethod.title") }}</p>
        </div>
        <div
          v-for="(paymentMethod, index) in paymentMethods"
          :key="paymentMethod + index"
          class="d-flex"
        >
          <div class="form-check">
            <input
              :id="paymentMethod.type"
              :value="paymentMethod.type"
              v-model="paymentMethodSelected"
              class="form-check-input"
              type="radio"
              name="exampleRadios"
            >
            <label
              :for="paymentMethod.type"
              class="form-check-label ml-2">
              {{ $t(getPaymentMethodName(paymentMethod.type)) }}
            </label>
          </div>
        </div>
      </v-flex>
      <!--  -->
      <v-flex
        class="mt-2"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="d-flex mt-4">
          <div>
            <span class="d-block font-weight-bold">{{
              $t("thirdParty.total")
            }}</span>
            <span
              class="font-weight-bold"
              style="color: #ff6d6d;">{{
                promotionCodeValid ? formatMoney(getFinalPrice()) : getTotalPrice()
              }}</span>
          </div>
          <v-btn
            :loading="isLoading"
            color="#ff6d6d"
            style="color: #fff;"
            @click="onBuyVoucherDirectly()"
          >
            {{ $t("thirdParty.order") }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <payment-info-modal
      ref="confirmModal"
      @confirm="onConfirmBuyVoucherDirectly"/>
    <momo-payment-modal
      ref="paymentMomoModal" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import VoucherProductApplyType from 'enum/voucherProductApplyType'
import ToastType from 'enum/toastType'
import StoreChannelType from 'enum/storeChannelType'
import paymentMethodType from 'enum/paymentMethodType'
import stringUtils from 'utils/stringUtils'
import paymentInfoModal from './PaymentInfoModal'
import momoPaymentModal from './MomoPaymentModal'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  components: {
    paymentInfoModal,
    momoPaymentModal
  },
  data: () => ({
    cartList: [],
    promotionCodeRules: [],
    promotionCode: null,
    valid: false,
    promotionCodeValid: false,
    priceReduced: 0,
    finalPrice: 0,
    promotionType: null,
    promotionId: null,
    promotionCodeOrigin: null,
    isLoading: false,
    tax: 0,
    paymentMethods: [],
    paymentMethodSelected: null,
    e1: 2,
    orderCode: null,
    totalPrice: 0,
    userId: null,
    fullname: null,
    email: null,
    phone: null,
    PaymentMethodType: paymentMethodType,
    baoKimPaymentMethodSelect: null,
    agentDefaultId: null,
    promotionDataList: [],
    promotionTypeEnum: VoucherProductPromotionType
  }),
  computed: {
    ...mapGetters([
      'GET_VOUCHER_ORDER_CART_LIST_DATA',
      'SHIPMENT_DETAILS_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.getVoucherOrderCart(data)
    },
    promotionCode () {
      this.promotionCodeRules = []
    }
  },
  async created () {
    await this.getSettingConfig()
    //
    let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
    await this.getVoucherOrderCart(data)
    //
    let filter = {
      params: {
        storeChannelId: this.getStoreChannelId()
      }
    }
    this.GET_DELIVERY_INFO_DETAIL(filter).then(
      function (res) {
        let data = res.data
        this.phone = data.phone
        this.email = data.email
        this.fullname = data.fullname
      }.bind(this)
    )
    await this.getPaymentMethodsList()
    this.GET_USER_DATA()
    this.getBaoKimPaymentList()
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    goToDeliveryInfo: function () {
      this.$router.push({
        path: '/mobile-portal-delivery-info'
      })
    },
    /**
     * Remove product from cart
     */
    onRemoveProductFromCart: function (productName, index) {
      this.REMOVE_PRODUCT_FROM_CART(index).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    /**
     * Get price reduced
     */
    getPriceReduced: function () {
      // eslint-disable-next-line eqeqeq
      if (this.promotionType == VoucherProductPromotionType.PRICE) {
        return this.formatMoney(this.priceReduced)
        // eslint-disable-next-line eqeqeq
      } else if (this.promotionType == VoucherProductPromotionType.PERCENT) {
        return this.priceReduced + '%'
      }
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Get total price
     */
    getTotalPrice: function () {
      let price = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price +=
          (this.cartList[i].pricePromo
            ? this.cartList[i].pricePromo
            : this.cartList[i].price) * this.cartList[i].quantity
      }
      price = price > 0 ? price : 0
      this.totalPrice = price
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Get total price with fee
     */
    getTotalPriceWithFee: function () {
      let price = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += this.cartList[i].price * this.cartList[i].quantity
      }
      return functionUtils.convertFormatNumberToMoney(
        (price + (price * this.tax) / 100).toString()
      )
    },
    getPaymentMethodsList: async function () {
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      await this.GET_PAYMENT_METHODS_IN_ENTITY_LIST(filter).then(
        function (res) {
          let data = res.data
          this.paymentMethods = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              type: data[i].payment_method,
              description: data[i].description
            }
            this.paymentMethods.push(obj)
          }
          this.paymentMethodSelected = this.paymentMethods.length > 0 ? this.paymentMethods[0].type : null
        }.bind(this)
      )
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    onBuyVoucherDirectly: function () {
      if (this.storeChannelsIdInRouteParams() !== null &&
        parseInt(this.storeChannelsIdInRouteParams()) === StoreChannelType.LIEN_VIET_POST_BANK) {
        this.onConfirmViVietPaymentMethod()
      } else {
        if (this.cartList.length === 0 || !this.orderCode) {
          this.ON_SHOW_TOAST({
            message: this.$t('cart.noProductsInCart'),
            styleType: ToastType.ERROR
          })
          return
        }
        let method = this.paymentMethods.find(element => {
          return element.type === this.paymentMethodSelected
        })
        let priceValue = this.promotionCodeValid ? this.formatMoney(this.getFinalPrice()) : this.getTotalPrice()
        this.$refs.confirmModal.onShowModal(this.orderCode, this.paymentMethodSelected, method ? method.description : '', priceValue)
      }
    },
    onConfirmBuyVoucherDirectly: function (data) {
      if ((this.paymentMethodSelected === paymentMethodType.CASH_ON_DELIVERY) || (this.paymentMethodSelected === paymentMethodType.BANK_TRANSFER)) {
        this.processBuyVoucherDirectly()
      } else if (this.paymentMethodSelected === paymentMethodType.BAO_KIM_WALLET) {
        this.baoKimPaymentMethodSelect = data.baoKimPaymentMethodSelect
        this.processBaoKimPayment()
      } else {
        if (this.paymentMethodSelected === paymentMethodType.MOMO_WALLET) {
          this.processMomoPayment()
        }
      }
    },
    processBuyVoucherDirectly: async function () {
      if (this.cartList.length === 0 || !this.orderCode) {
        this.ON_SHOW_TOAST({
          message: this.$t('cart.noProductsInCart'),
          styleType: ToastType.ERROR
        })
        return
      }
      let filter = {
        cartList: this.cartList,
        promotionCode: this.promotionCodeOrigin,
        promotionType: this.promotionType,
        promotionValue: this.priceReduced,
        promotionId: this.promotionId,
        promotionCodeValid: this.promotionCodeValid,
        deliveryInfo: this.SHIPMENT_DETAILS_DATA.id,
        paymentMethod: this.paymentMethodSelected,
        orderCode: this.orderCode,
        storeChannelId: this.getStoreChannelId(),
        promotionList: this.promotionDataList
      }
      this.isLoading = true
      await this.ORDER_VOUCHER_PRODUCT(filter)
        .then(
          function () {
            // this.currentStep = PaymentSteps.DELIVERY_INFO
            this.isLoading = false
            this.callbackVoucherOrderCart()
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.promotionCode = null
            this.promotionCodeValid = false
            this.priceReduced = null
            this.orderCode = null
            this.removePromotionSession()
            this.onGoCart()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.$refs.confirmModal.onHideModal()
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(errorText),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('common.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    processMomoPayment: function () {
      // let entityId = sessionStorage.getItem('entityId')
      let entityId = this.getPartnershipEntityId(this.agentDefaultId)
      let filter = {
        // cartList: this.cartList,
        entityId: entityId,
        // promotionCode: this.promotionCodeOrigin,
        // promotionType: this.promotionType,
        // promotionValue: this.priceReduced,
        // promotionId: this.promotionId,
        // promotionCodeValid: this.promotionCodeValid,
        // deliveryInfo: this.SHIPMENT_DETAILS_DATA.id,
        // paymentMethod: this.paymentMethodSelected,
        price: this.getTotalPrice(),
        orderCode: this.orderCode
      }
      this.isLoading = true
      // TODO: recheck
      this.REQUEST_MOMO_PAYURL(filter)
        .then(
          function (res) {
            let data = res.data.data
            this.isLoading = false
            this.$refs.paymentMomoModal.onShowModal(data.payUrl, data.qrCodeUrl, data.orderId, data.amount)
            // this.currentStep = PaymentSteps.DELIVERY_INFO
            // this.$toast.open({
            //   message: this.$t("cart.successfullyOrderedVoucher"),
            //   type: "success",
            //   ...this.$toastConfig,
            // });
            // this.promotionCode = null
            // this.promotionCodeValid = false
            // this.priceReduced = null
            // this.orderCode = null
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(errorText),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('common.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    getVoucherOrderCart: async function (data) {
      this.cartList = []
      data.forEach(e => {
        let pricePromo = e.extra_info.product ? e.extra_info.product.price_promo : null
        let price = pricePromo !== null ? pricePromo : e.voucher_info.price
        let item = {
          id: e.voucher_info.workpiece_id,
          image: e.voucher_info.image,
          quantity: e.quantity,
          price: price,
          name: e.voucher_info.name,
          serial: e.voucher_info.serial,
          voucherOrderCartId: e.voucher_order_cart_id,
          warehouse: e.warehouse,
          issuanceId: e.issuance_id,
          numberOfUsesVoucherValid: e.number_of_uses_voucher_valid,
          isProductPromotion: false,
          promotionIdApply: null
        }
        this.cartList.push(item)
      })
      this.getPromotionSession()
      let orderCodeFilter = {
        storeChannelId: this.getStoreChannelId()
      }
      if (data.length !== 0) {
        await this.GET_CREATE_ORDER_CODE_PREPARE(orderCodeFilter).then(
          function (res) {
            let data = res.data
            this.orderCode = data.code
          }.bind(this)
        )
      }
    },
    callbackVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: this.getStoreChannelId()
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    onGoCart: function () {
      this.$router.push({
        name: 'MobilePortalCart'
      })
    },
    getBaoKimPaymentList: function () {
      this.GET_BAO_KIM_PAYMENT_LIST()
    },
    processBaoKimPayment: function () {
      if (this.cartList.length === 0 || !this.orderCode) {
        this.ON_SHOW_TOAST({
          message: this.$t('cart.noProductsInCart'),
          styleType: ToastType.ERROR
        })
        return
      }
      let filter = {
        cartList: this.cartList,
        promotionCode: this.promotionCodeOrigin,
        promotionType: this.promotionType,
        promotionValue: this.priceReduced,
        promotionId: this.promotionId,
        promotionCodeValid: this.promotionCodeValid,
        deliveryInfo: this.SHIPMENT_DETAILS_DATA.id,
        paymentMethod: this.paymentMethodSelected,
        orderCode: this.orderCode,
        storeChannelId: this.getStoreChannelId(),
        baoKimPaymentMethodSelect: this.baoKimPaymentMethodSelect,
        domainSuccess: stringUtils.DOMAIN_BASE_PATH_ADMIN,
        promotionList: this.promotionDataList
      }
      this.isLoading = true
      this.ADD_BAO_KIM_PAYMENT(filter)
        .then(
          function (res) {
            // this.currentStep = PaymentSteps.DELIVERY_INFO
            this.isLoading = false
            this.callbackVoucherOrderCart()
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.promotionCode = null
            this.promotionCodeValid = false
            this.priceReduced = null
            this.orderCode = null
            this.removePromotionSession()
            this.onGoCart()
            let data = res.data
            let paymentUrl = data.payment_url
            window.open(paymentUrl, '_self')
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(errorText),
                styleType: ToastType.ERROR
              })
            } else {
              let message = error.response.data.message
              this.ON_SHOW_TOAST({
                message: message,
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    getSettingConfig: async function () {
      let storeChannelsKey = stringUtils.storeChannelsKey + `${this.storeChannelsIdInRouteParams()}`
      let filter = {
        params: {
          getWithKey: true,
          key: storeChannelsKey
        }
      }
      await this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.agentDefaultId = parseInt(data[0].value)
          }
        }.bind(this)
      )
    },
    /**
     * Apply promotion code
     */
    applyPromotionCode: function () {
      if (functionUtils.isEmptyString(this.promotionCode)) {
        this.ON_SHOW_TOAST({
          message: this.$t('cart.promotion.code'),
          styleType: ToastType.ERROR
        })
        return
      }
      if (this.$refs.entryForm.validate()) {
        let filter = {
          code: this.promotionCode,
          orderCode: this.orderCode,
          storeChannelId: this.getStoreChannelId()
        }
        this.CHECK_PROMOTION_CODE(filter)
          .then(
            function (res) {
              let data = res.data.data
              // this.priceReduced = data.value
              // this.promotionType = data.type
              // this.promotionCodeValid = true
              // this.promotionId = data.id
              // this.promotionCodeOrigin = data.code
              let index = this.promotionDataList.findIndex(x => x.promotionId === data.id)
              if (index === -1) {
                let voucherName = ''
                let voucherPromotionId = null
                let checkPromotionOnVoucher = this.cartList.findIndex(
                  x => data.workpiece.includes(x.id) &&
                  x.isProductPromotion === false &&
                  x.promotionIdApply === null)
                let checkPromotionInOrderCart = this.cartList.findIndex(
                  x => data.workpiece.includes(x.id))
                if (data.apply_type === VoucherProductApplyType.VOUCHER) {
                  // voucher use other promotion
                  if (checkPromotionInOrderCart !== -1) {
                    if (checkPromotionOnVoucher === -1) {
                      this.ON_SHOW_TOAST({
                        message: this.$t('cart.promotionCodeAlreadyUsed'),
                        styleType: ToastType.ERROR
                      })
                      return
                    }
                    // update promotion list
                    voucherName = this.cartList[checkPromotionOnVoucher].name
                    voucherPromotionId = this.cartList[checkPromotionOnVoucher].id
                    this.cartList[checkPromotionOnVoucher].isProductPromotion = true
                    this.cartList[checkPromotionOnVoucher].promotionIdApply = data.id
                    let item = {
                      promotionCode: data.code,
                      promotionType: data.type,
                      promotionValue: data.value,
                      promotionId: data.id,
                      promotionCodeValid: true,
                      applyType: data.apply_type,
                      voucherPromotionId: voucherPromotionId,
                      voucherName: voucherName
                    }
                    this.promotionDataList.push(item)
                  }
                } else {
                  // check promotion on bill
                  let checkPromotionOnBill = this.promotionDataList.findIndex(x => x.applyType === VoucherProductApplyType.ORDER)
                  if (checkPromotionOnBill !== -1) {
                    this.ON_SHOW_TOAST({
                      message: this.$t('cart.promotionCodeAlreadyUsed'),
                      styleType: ToastType.ERROR
                    })
                    return
                  }
                  //
                  let item = {
                    promotionCode: data.code,
                    promotionType: data.type,
                    promotionValue: data.value,
                    promotionId: data.id,
                    promotionCodeValid: true,
                    applyType: data.apply_type,
                    voucherPromotionId: voucherPromotionId,
                    voucherName: voucherName
                  }
                  this.promotionDataList.push(item)
                }
              }
              this.promotionCodeValid = this.promotionDataList.length > 0
              this.promotionCode = null
              // short promotion list
              this.promotionDataList.sort((a, b) => b.applyType - a.applyType)
              this.setPromotionSession()
            }.bind(this)
          )
          .catch(
            function (error) {
              // this.promotionCodeValid = false
              this.promotionCodeValid = this.promotionDataList.length > 0
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  message: this.$t(errorText),
                  styleType: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              }
              this.$refs.entryForm.validate()
            }.bind(this)
          )
      }
    },
    /**
     * Promotion code not exist
     */
    promotionCodeNotExist (text) {
      return this.$t(text)
    },
    setPromotionSession: function () {
      sessionStorage.setItem('promotionSession', JSON.stringify(this.promotionDataList))
    },
    getPromotionSession: function () {
      let promotionSessionData = sessionStorage.getItem('promotionSession')
      if (promotionSessionData) {
        this.promotionDataList = JSON.parse(promotionSessionData)
      }
      this.promotionDataList.forEach(e => {
        let checkPromotionOnVoucher = this.cartList.findIndex(x => e.voucherPromotionId === x.id && x.isProductPromotion === false)
        if (e.applyType === VoucherProductApplyType.VOUCHER) {
          // update voucher is promotion
          if (checkPromotionOnVoucher !== -1) {
            this.cartList[checkPromotionOnVoucher].isProductPromotion = true
            this.cartList[checkPromotionOnVoucher].promotionIdApply = e.promotionId
          }
        }
      })
      this.promotionCodeValid = this.promotionDataList.length > 0
    },
    removePromotionSession: function () {
      sessionStorage.removeItem('promotionSession')
    },
    /**
     * Get final price
     */
    getFinalPrice: function () {
      let price = 0
      let promotionProductPrice = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        let productPrice = this.cartList[i].price * this.cartList[i].quantity
        price += productPrice
        promotionProductPrice += this.getPricePromotionVoucher(this.cartList[i].id, this.cartList[i].price)
      }
      // total price - promotion price
      price = price - promotionProductPrice
      // minus promotion on bill
      let promotionTotalObj = this.getPromotionOnBill()
      price = (price + (price * this.tax) / 100)
      if (this.promotionCodeValid) {
        if (promotionTotalObj.price) {
          price = price - promotionTotalObj.price
        }
        if (promotionTotalObj.percent > 0) {
          price = price - (price * promotionTotalObj.percent / 100)
        }
      }
      this.totalPromotionPrice = promotionProductPrice + promotionTotalObj
      return price > 0 ? price : 0
    },
    getPricePromotionVoucher: function (productId, productTotalPrice) {
      let result = 0
      let promotionData = this.promotionDataList.filter(x => x.applyType === VoucherProductApplyType.VOUCHER && x.voucherPromotionId === productId)
      if (promotionData.length > 0) {
        if (promotionData[0].promotionType === VoucherProductPromotionType.PRICE) {
          result = promotionData[0].promotionValue
        }
        if (promotionData[0].promotionType === VoucherProductPromotionType.PERCENT) {
          result = productTotalPrice * promotionData[0].promotionValue / 100
        }
      }
      return result
    },
    // get promotion on bill
    getPromotionOnBill: function () {
      let pricePromotionOnBill = 0
      let percentPromotionOnBill = 0
      let promotionOnBillList = this.promotionDataList.filter(x => x.applyType === VoucherProductApplyType.ORDER)
      promotionOnBillList.forEach(e => {
        if (e.promotionType === VoucherProductPromotionType.PRICE) {
          pricePromotionOnBill += e.promotionValue
        }
        if (e.promotionType === VoucherProductPromotionType.PERCENT) {
          percentPromotionOnBill += e.promotionValue
        }
      })
      return {
        'price': pricePromotionOnBill,
        'percent': percentPromotionOnBill
      }
    },
    onRemovePromotion: function (data, i) {
      this.promotionDataList.splice(i, 1)
      if (data.applyType === VoucherProductApplyType.VOUCHER && data.voucherPromotionId !== null) {
        let index = this.cartList.findIndex(x => x.id === data.voucherPromotionId)
        if (index !== -1) {
          this.cartList[index].isProductPromotion = false
          this.cartList[index].promotionIdApply = null
        }
      }
      this.promotionCodeValid = this.promotionDataList.length > 0
      this.setPromotionSession()
    },
    onConfirmViVietPaymentMethod: function () {
      let userId = this.viVietSessionArr[4]
      let filter = {
        cartList: this.cartList,
        orderCode: this.orderCode,
        userId: this.userId
      }
      this.CONFIRM_ORDER_ON_VI_VIET(filter).then(
        function () {
          let accessCode = this.viVietSessionArr[0]
          let agentSession = this.viVietSessionArr[1]
          let merchantSite = this.viVietSessionArr[2]
          let vivietEcomUrl = this.viVietSessionArr[5]
          let returnUrl = functionUtils.removeDoubleSlashesFromUrl(`${stringUtils.DOMAIN_BASE_PATH_ADMIN}/payment-results`)
          let filter = {
            accessCode: accessCode,
            agentSession: agentSession,
            merchantSite: merchantSite,
            userId: userId,
            orderCode: this.orderCode,
            agentSite: '1',
            returnUrl: returnUrl,
            totalPrice: this.totalPrice.toString()
          }
          this.callbackVoucherOrderCart()
          this.isLoading = true
          this.PAYMENT_ORDER_ON_VI_VIET(filter).then(
            function (res) {
              let data = res.data
              this.isLoading = false
              window.open(`${vivietEcomUrl}/?merchant_site=${merchantSite}&data=${data.data}&key=${data.key}`)
            }.bind(this)
          )
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('errorBuying.errorBuying'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    ...mapActions([
      'REMOVE_PRODUCT_FROM_CART',
      'GET_CREATE_ORDER_CODE_PREPARE',
      'ON_SHOW_TOAST',
      'GET_DELIVERY_INFO_DETAIL',
      'ORDER_VOUCHER_PRODUCT',
      'REQUEST_MOMO_PAYURL',
      'GET_PAYMENT_METHODS_IN_ENTITY_LIST',
      'GET_USER_DATA',
      'GET_VOUCHER_ORDER_CART_LIST',
      'GET_BAO_KIM_PAYMENT_LIST',
      'ADD_BAO_KIM_PAYMENT',
      'GET_OBJECT_SETTING',
      'CHECK_PROMOTION_CODE',
      'CONFIRM_ORDER_ON_VI_VIET',
      'PAYMENT_ORDER_ON_VI_VIET'
    ])
  }
}
</script>

<style lang="scss" scoped>
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
</style>
