<template>
  <v-dialog
    v-model="isShowModal"
    transition="dialog-top-transition"
    max-width="600"
    persistent>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          flat
          color="primary"
          dark>
          <img
            v-if="paymentMethod==PaymentMethodType.MOMO_WALLET"
            src="@/assets/logo_momo.png"
            class="paymentLogo">
          <img
            v-else-if="paymentMethod==PaymentMethodType.ZALOPAY_WALLET"
            src="@/assets/logo_zalopay.png"
            class="paymentLogo">
          {{ $t(getPaymentMethodName(paymentMethod)) }}
          <v-spacer />
          <v-btn
            icon
            dark
            @click="dialog.value = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="row mt-3">
            <div class="col-sm-5">
              <div
                align="center"
                justify="center"
                class="qr-container">
                <div class="qr-view">
                  <qrcode-vue
                    :value="payment.payUrl"
                    :size="180"
                    level="H"
                    render-as="svg"
                  />
                </div>
                <div class="mt-2">
                  {{ $t("productOrder.tableList.header.totalPrice") }}: <span class="font-weight-bold"> {{ formatMoney(payment.price) }} VND</span>
                </div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="text-left">
                <div
                  class="mb-1"
                  style="color: red; font-style: italic;">{{ $t('paymentMethod.intro.instructions') }}</div>
                <h3>{{ $t('paymentMethod.intro.scanQR') }}</h3>
                <dl>
                  <dt><h4>{{ $t('paymentMethod.intro.step', {idx: 1}) }}</h4></dt>
                  <dd>{{ $t('paymentMethod.intro.openApp', {appname: 'MoMo'}) }}</dd>
                  <dt><h4>{{ $t('paymentMethod.intro.step', {idx: 2}) }}</h4></dt>
                  <dd>{{ $t('paymentMethod.intro.scanning', {appname: 'MoMo'}) }}</dd>
                  <dt><h4>{{ $t('paymentMethod.intro.step', {idx: 3}) }}</h4></dt>
                  <dd>{{ $t('paymentMethod.intro.scanToPay') }}</dd>
                </dl>
              </div>
              <div
                class="mt-3 pt-1 pb-4"
                style="background-color: #63c5fd">
                <span>{{ $t('paymentMethod.intro.paymentTimeout') }}</span>
                <div class="mt-2">
                  <b class="number-cell mx-1">{{ getMinuteDisplay() }}</b>
                  <b class="number-cell mx-1">{{ getSecondDisplay() }}</b>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import PaymentMethodType from 'enum/paymentMethodType'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    isShowModal: false,
    payment: {
      payUrl: null,
      qrCodeUrl: null,
      orderCode: null,
      price: 0
    },
    remainTimer: 0,
    PaymentMethodType: PaymentMethodType,
    paymentMethod: PaymentMethodType.MOMO_WALLET
  }),
  computed: {
    ...mapGetters([
    ])
  },
  watch: {
    remainTimer: {
      handler (value) {
        if (value > 0 && this.isShowModal) {
          setTimeout(() => {
            this.remainTimer--
            this.onInterval(this.remainTimer)
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  created () {
  },
  methods: {
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    padLeadingZero: function (num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },
    getMinuteDisplay: function () {
      return this.padLeadingZero(Math.floor(this.remainTimer / 60))
    },
    getSecondDisplay: function () {
      return this.padLeadingZero(this.remainTimer % 60)
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    onConfirm: function () {
      this.isShowModal = false
      // this.$emit("confirm");
    },
    onShowModal: function (payUrl, qrCodeUrl, orderCode, price) {
      this.payment.payUrl = payUrl
      this.payment.qrCodeUrl = qrCodeUrl
      this.payment.orderCode = orderCode
      this.payment.price = price
      this.remainTimer = 120
      this.isShowModal = true
    },
    onInterval: function (remain) {
      if (remain === 0) { // Timeout
        this.isShowModal = false
      } else if (remain % 5 === 0) {
        // check payment status every 5 seconds
      }
    },
    ...mapActions([
    ])
  }
}
</script>

<style lang="scss" scoped>
input {
  color: #2c3e50;
  font-weight: bold;
}
.paymentLogo {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  vertical-align: middle;
}
.number-cell {
  background-color: #F44336;
  padding: 9px;
  border-radius: 9px;
}
.qr-container {
  background-color: whitesmoke;
  border-radius: 7px;
  padding: 7px;
}
.qr-view {
  background-color: white;
  border: 1px solid #868686;
  border-radius: 7px;
  padding: 10px;
  width: 200px;
  height: 200px;
}
</style>
